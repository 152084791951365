/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
import React from 'react';
import _ from 'lodash';
import { decorate, computed, action, observable, runInAction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Container, Segment, Header, Icon, Button, Label } from 'semantic-ui-react';

import { gotoFn } from '@amzn/base-ui/dist/helpers/routing';
import { swallowError, storage } from '@amzn/base-ui/dist/helpers/utils';
import storageKeys from '@amzn/base-ui/dist/models/constants/local-storage-keys';
import {
  isStoreLoading,
  isStoreEmpty,
  isStoreNotEmpty,
  isStoreError,
  isStoreReady,
} from '@amzn/base-ui/dist/models/BaseStore';
import ErrorBox from '@amzn/base-ui/dist/parts/helpers/ErrorBox';
import ProgressPlaceHolder from '@amzn/base-ui/dist/parts/helpers/BasicProgressPlaceholder';

import { filterNames } from '../../models/environments-sc/ScEnvironmentsStore';
import ScEnvironmentCard from './ScEnvironmentCard';
import ScEnvironmentsFilterButtons from './parts/ScEnvironmentsFilterButtons';
import ReactTable from 'react-table';
import { workspacesRename, projectsRename } from '../../helpers/settings';

// expected props
// - scEnvironmentsStore (via injection)
// - envTypesStore (via injection)
// - projectsStore (via injection)
class ScEnvironmentsList extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      const key = storageKeys.workspacesFilterName;
      const name = storage.getItem(key) || filterNames.ALL;
      storage.setItem(key, name);
      this.selectedFilter = name;
      this.provisionDisabled = false;
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const store = this.envsStore;
    swallowError(store.load());
    store.startHeartbeat();

    const typesStore = this.envTypesStore;
    if (!isStoreReady(typesStore)) {
      swallowError(typesStore.load());
    }
  }

  componentWillUnmount() {
    const store = this.envsStore;
    store.stopHeartbeat();
  }

  get isAppStreamEnabled() {
    return process.env.REACT_APP_IS_APP_STREAM_ENABLED === 'true';
  }

  get envTypesStore() {
    return this.props.envTypesStore;
  }

  get envsStore() {
    return this.props.scEnvironmentsStore;
  }

  getProjects() {
    const store = this.getProjectsStore();
    return store.list;
  }

  getProjectsStore() {
    const store = this.props.projectsStore;
    store.load();
    return store;
  }

  handleCreateEnvironment = event => {
    event.preventDefault();
    event.stopPropagation();

    const goto = gotoFn(this);
    goto(`/${workspacesRename.pluralLower}/create`);
  };

  handleSelectedFilter = name => {
    this.selectedFilter = name;
    const key = storageKeys.workspacesFilterName;
    storage.setItem(key, name);
  };

  render() {
    const store = this.envsStore;
    let content = null;
    const projects = this.getProjects();
    const appStreamProjectIds = _.map(
      _.filter(projects, proj => proj.isAppStreamConfigured),
      'id',
    );

    runInAction(() => {
      if (this.isAppStreamEnabled && _.isEmpty(appStreamProjectIds)) this.provisionDisabled = true;
    });

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0" />;
    } else if (isStoreLoading(store)) {
      content = <ProgressPlaceHolder segmentCount={3} />;
    } else if (isStoreEmpty(store)) {
      content = this.renderEmpty();
    } else if (isStoreNotEmpty(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <Container className="mt3 animated fadeIn">
        {this.renderTitle()}
        {this.provisionDisabled && this.renderMissingAppStreamConfig()}
        {content}
      </Container>
    );
  }

  renderMissingAppStreamConfig() {
    return (
      <>
        <Segment placeholder className="mt2">
          <Header icon className="color-grey">
            <Icon name="lock" />
            Missing association with AppStream projects
            <Header.Subheader>
              Since your projects are not associated to an AppStream-configured account, creating a new workspace is
              disabled. Please contact your administrator.
            </Header.Subheader>
          </Header>
        </Segment>
      </>
    );
  }
  
   
  getUserDisplayName() {
    return this.props.userDisplayName;
  }
  
  handleViewDetail = (projectId) => {
    const goto = gotoFn(this);
    goto(`/${workspacesRename.pluralLower}/id/`+projectId);
  };

  renderMain() {
    const store = this.envsStore;
    const selectedFilter = this.selectedFilter;
    const list = store.filtered(selectedFilter);
    const isEmpty = _.isEmpty(list);
    
    //console.log("list", list);
    const terminatedFilter = _.filter(
      list, function(workspace) {
        return workspace.status != "TERMINATED";
      });
    //console.log("Term Filter", terminatedFilter);
    const projStore = this.getProjectsStore();
    const userDisplayName = this.getUserDisplayName();
    
    const columns = [
      {
        Header: `${workspacesRename.singularCap} Name`,
        accessor: 'name',
      },
      {
        Header: `${projectsRename.singularCap} Name`,
        accessor: 'projectId',
        Cell: observer(cell => {
          const projCell = cell.original;
          const projId = projCell.projectId;
          const projObj = projStore.getProject(projId);
          const isSensitive = projObj.hasSensitiveData || false;
          return(<div>{projId} {isSensitive && <Popup trigger={<Icon name='exclamation triangle' color='red' />} content='This project uses sensitive, personally-identifiable data and is subject to additional data security requirements.' />}</div>);
        })
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
        Cell: observer(cell => {
          const createdCell = cell.original;
          const createdby = createdCell.createdBy;
          //const userDisplayName = this.getUserDisplayName();
          //const username = userDisplayName.getDisplayName({ uid: createdby });
          if(userDisplayName){
            const username = userDisplayName.getDisplayName({ uid: createdby });
            //console.log("username", username);
            return (<div>{username}</div>);
          }
        }),
      },
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: observer(cell => {
          const projCell = cell.original;
          const createdAt = projCell.createdAt;
          let date = new Date(createdAt);
          const month =  ("0" + (date.getMonth() + 1)).slice(-2);
          return `${date.getFullYear()}-${month}-${date.getDate()}`;
        }),
      },
      /*{
        Header: 'Description',
        accessor: 'description',
      },*/
      {
        Header: 'Status',
        accessor: 'state',
        Cell: observer(cell => {
          const statusCell = cell.original;
          const display = statusCell.state.display;
          const color = statusCell.state.color;
          const spinner = statusCell.state.spinner;
          return (<div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
            <Label size="mini" color={color}>
              {spinner && <Icon name="spinner" loading />}
              {display}
            </Label></div>);
        }),
      },
      /*{
        Header: 'Project ID',
        accessor: 'projectId',
      },*/
      /*{
        Header: 'Env Type ID',
        accessor: 'envTypeId',
      },*/
      {
        Header: 'Details',
        accessor: 'viewDetail',
        Cell: observer(cell => {
          const originalRow = cell.original;
          return (
            <div style={{ textAlign: 'center' }}>
              <Button basic size="mini" className="mt1 mb1 ml2" onClick={(evt) => { this.handleViewDetail(originalRow.id) }}>
                View Detail
              </Button>
            </div>
          );
        }),
        filterable: false
      }
    ];
    
  const pageSize = list.length;
  const pagination = list.length > pageSize;
    
   return ( <div data-testid="workspaces">
      {!isEmpty && <ReactTable
        data={terminatedFilter}
        showPagination={pagination}
        defaultPageSize={pageSize}
        columns={columns}
        className="-striped -highlight"
        filterable
        defaultFilterMethod={(filter, row) => {
          const columnValue = String(row[filter.id]).toLowerCase();
          const filterValue = filter.value.toLowerCase();
          return columnValue.indexOf(filterValue) >= 0;
        }}
      />}
       {isEmpty && (
          <Segment placeholder>
            <Header icon className="color-grey">
              <Icon name="server" />
              No research {workspacesRename.pluralLower} matching the selected filter.
              <Header.Subheader>Select &apos;All&apos; to view all the {workspacesRename.pluralLower}</Header.Subheader>
            </Header>
          </Segment>
        )}
    </div>);

    // return (
    //   <div data-testid="workspaces">
    //     <ScEnvironmentsFilterButtons
    //       selectedFilter={selectedFilter}
    //       onSelectedFilter={this.handleSelectedFilter}
    //       className="mb3"
    //     />
    //     {!isEmpty &&
    //       _.map(list, item => (
    //         <Segment className="p3 mb4" clearing key={item.id}>
    //           <ScEnvironmentCard scEnvironment={item} />
    //         </Segment>
    //       ))}
    //     {isEmpty && (
    //       <Segment placeholder>
    //         <Header icon className="color-grey">
    //           <Icon name="server" />
    //           No research workspaces matching the selected filter.
    //           <Header.Subheader>Select &apos;All&apos; to view all the workspaces</Header.Subheader>
    //         </Header>
    //       </Segment>
    //     )}
    //   </div>
    // );
  }

  renderEmpty() {
    return (
      <Segment data-testid="workspaces" placeholder>
        <Header icon className="color-grey">
          <Icon name="server" />
          No research {workspacesRename.pluralLower}
          <Header.Subheader>To create a research {workspacesRename.singularLower}, click Create Research {workspacesRename.singularCap}.</Header.Subheader>
        </Header>
      </Segment>
    );
  }

  renderTitle() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="server" className="align-top" />
          <Header.Content className="left-align">Research {workspacesRename.pluralCap} {/*this.renderTotal()*/}</Header.Content>
        </Header>
        <div>
          <Button
            data-testid="create-workspace"
            color="blue"
            size="medium"
            disabled={this.provisionDisabled}
            basic
            onClick={this.handleCreateEnvironment}
          >
            Create Research {workspacesRename.singularCap}
          </Button>
        </div>
      </div>
    );
  }

  renderTotal() {
    const store = this.envsStore;
    if (isStoreError(store) || isStoreLoading(store)) return null;

    return <Label circular>{store.total}</Label>;
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(ScEnvironmentsList, {
  selectedFilter: observable,
  provisionDisabled: observable,
  envsStore: computed,
  envTypesStore: computed,
  handleCreateEnvironment: action,
  handleSelectedFilter: action,
});

export default inject(
  'scEnvironmentsStore',
  'projectsStore',
  'envTypesStore',
  'userDisplayName',
)(withRouter(observer(ScEnvironmentsList)));
