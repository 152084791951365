/*
 * Modification of UploadStudyFiles.js ("Upload Files" button)
 * to create a "Delete Data Set" button for use in StudyRow.js.
 * 
 * Created by: Hunter Irving
 * Date: 9/26/2022
*/

import React from 'react';
import _ from 'lodash';
import { inject,observer } from 'mobx-react';
import { action, decorate, observable, runInAction } from 'mobx';
import { Button, Header, Modal } from 'semantic-ui-react';
import { deleteStudy } from '../../helpers/api';
import { displayError, displaySuccess } from '@amzn/base-ui/dist/helpers/notification';
import { Datasets } from '../../models/resources/page-properties.js';
import { studiesRename, projectsRename, workspacesRename } from '../../helpers/settings';

// expected props
// - studyId
class DeleteStudyFiles extends React.Component {
    constructor(props) {
        super(props);
        runInAction(() => {
            this.modalOpen = false;
        });
        const envsStore = this.props.scEnvironmentsStore;
        envsStore.doLoad();
    }
    
    get envsStore() {
        return this.props.scEnvironmentsStore;
    }
    
    getUserDisplayName() {
        return this.props.userDisplayName;
    }
    
    get study() {
        return this.props.study;
    }
    
    getProjectsStore() {
        const store = this.props.projectsStore;
        return store;
    }
    
    getEnvironments() {
        const envsStore = this.envsStore;
        return envsStore.list;
    }
    
    checkEnvironmentsForStudy(studyId) {
        runInAction(async () => {
            const envsStore = this.props.scEnvironmentsStore;
            await envsStore.doLoad();
            //console.log('runInAction');
            
            let isStudyIdAvail = false;
        let workspace =null;
        const envList = this.getEnvironments();
        //console.log("envList", envList);
        
        const filteredList = envList.map((env)=> {
            console.log(env.status);
            console.log(env);
            if(env.status == 'PENDING' || env.status == 'COMPLETED' || env.status == 'STOPPED' ){
                return env;
            }else{
                return;
            }
        });
        
        //console.log("filteredList",filteredList);
        const updatedList = _.without(filteredList, undefined);
        //console.log("updatedList",updatedList);
        
        const filteredStudyIds = updatedList.map((env)=>{
            const studies = env.studyIds
            const studyValues = studies.map(study=>study);
            //console.log("studyValues",studyValues);
            //const isSudyId = studyValues.toString().toLowerCase().indexOf(studyId.toLowerCase()) > -1 ;
            if(studyValues.toString().toLowerCase().indexOf(studyId.toLowerCase()) > -1 ){
                isStudyIdAvail =true;
                workspace = env;
                //console.log("isStudyIdAvail",workspace);
            }
            // return studyValues;
          // return  studyValues.toString().toLowerCase().indexOf(env.toLowerCase()) > -1 ;
        });
        
        //const project =  prjStore.getProject(study.projectId);
        

        //get projectAdmins..?
        //console.log('project', project);
        //console.log('projectAdmins', projectAdmins);
        /*const adminNames = _.map(projectAdmins, (u) =>{
                  if(/\s/.test(u.firstName)) {
                     return u.firstName;
                   }
                   else {
                     return u.firstName + " " + u.lastName;
                   }
                  //return  u.firstName;
                }).join(', ');*/
        //console.log('admin?', projectAdmins[0]);

        if(isStudyIdAvail){
                const userDisplayName = this.getUserDisplayName();
                const projectsStore = this.getProjectsStore();
                const project =  projectsStore.getProject(workspace.projectId);
                const projectAdmins = project.projectAdmins;
                displayError(`The selected ${studiesRename.singularLower} is used in one or more ${workspacesRename.pluralLower}. Please contact the ${workspacesRename.singularCap} Admin or the ${projectsRename.singularCap} Admin to have them terminate the ${workspacesRename.singularLower}. Only then will you be able to proceed with the deletion.
                <br/></br>${workspacesRename.singularCap} Admin: ${userDisplayName.getDisplayName({ uid: workspace.createdBy })}<br/><br/>

                ${workspacesRename.singularCap} Name: ${workspace.name}<br/><br/>
                
                ${projectsRename.singularCap} Owner: ${userDisplayName.getDisplayName({uid: projectAdmins[0]})}<br/><br/>
                
                ${projectsRename.singularCap} Name: ${workspace.projectId}<br/><br/>
                
                `);
        }else{
            runInAction(() => {
                //console.log("Modal Open ",this);
                this.modalOpen = true;
            });
             
        }
        });
        
    }
    
    async deleteHandler(){
        try{
            await deleteStudy(this.props.studyId);
            this.cleanUp();
            runInAction(() => {
                const map = this.props.studiesStoresMap;
                map['my-studies'].load();
                map['organization'].load();
                displaySuccess(`${studiesRename.singularCap} deleted successfully`);
                window.location = `/${studiesRename.pluralLower}`;
            });
        }
        catch (err) {
            displayError(err);
        }
    }
    
    cleanUp() {
        runInAction(() => {
            this.modalOpen = false;
        });
    }
    
    handleModalClose = () => {
        this.cleanUp();
      };
    
    handleClickCancelButton = () => {
        runInAction(() => {
            this.cleanUp();
        });
    }

    render() {
        return (
            <Modal
                closeIcon
                trigger={this.renderTrigger()}
                open={this.modalOpen}
                onClose={this.handleModalClose}
                className="dataDeleteModal"
            >
                <div className="mt2 animated fadeIn">
                    <Header as="h3" icon textAlign="center" className="mt3" color="grey">
                        Confirm {studiesRename.singularCap} Deletion
                    </Header>
                    <div className="mx3 animated fadeIn">
                        Click "Delete" to permanently delete this {studiesRename.singularLower}.
                        This action cannot be undone.
                    </div>
                    <Button
                        floated="right"
                        color="blue"
                        icon
                        className="ml2"
                    onClick={this.deleteHandler.bind(this)}
                    >
                        Delete
                    </Button>
                    <Button
                        floated="right"
                        color="grey"
                        icon
                        className="ml2"
                        onClick= {this.handleClickCancelButton}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
        );
    }

    renderTrigger() {
        return (
            <Button
                floated="right"
                color="blue"
                basic
                // onClick={action(() => {
                //     this.checkEnvironmentsForStudy.bind(this)
                //     //this.modalOpen = true;
                // })}
                
                 onClick={this.checkEnvironmentsForStudy.bind(this,this.props.studyId)}
            >
                Delete {studiesRename.singularCap}
            </Button>
        );
    }
}

decorate(DeleteStudyFiles, {
    modalOpen: observable,
});

export default inject('studiesStoresMap', 'userDisplayName', 'userStore', 'projectsStore', 'scEnvironmentsStore')(observer(DeleteStudyFiles));
