/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
import React from 'react';
import ReactTable from 'react-table';

import _ from 'lodash';
import { decorate, computed, action, observable, runInAction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Header, Icon, Segment, Container, Label, Button,Tab,Table, Search, Input, Popup } from 'semantic-ui-react';

import By from '../helpers/By';

import { gotoFn } from '@amzn/base-ui/dist/helpers/routing';
import { swallowError, storage } from '@amzn/base-ui/dist/helpers/utils';
import storageKeys from '@amzn/base-ui/dist/models/constants/local-storage-keys';
import {
  isStoreLoading,
  isStoreEmpty,
  isStoreNotEmpty,
  isStoreError,
  isStoreReady,
} from '@amzn/base-ui/dist/models/BaseStore';
import ErrorBox from '@amzn/base-ui/dist/parts/helpers/ErrorBox';
import ProgressPlaceHolder from '@amzn/base-ui/dist/parts/helpers/BasicProgressPlaceholder';

import { filterNames } from '../../models/environments-sc/ScEnvironmentsStore';


import StudiesPage from '../studies/StudiesPage';
import { Datasets, Environments } from '../../models/resources/page-properties.js';
import { studiesRename, workspacesRename, projectsRename } from '../../helpers/settings';


//import ScEnvironmentCard from './ScEnvironmentCard';
import ScEnvironmentsFilterButtons from '.././environments-sc/parts/ScEnvironmentsFilterButtons';

// expected props
// - scEnvironmentsStore (via injection)
// - envTypesStore (via injection)
// - projectsStore (via injection)
class ScEnvironmentsList extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      const key = storageKeys.workspacesFilterName;
      const name = storage.getItem(key) || filterNames.ALL;
      storage.setItem(key, name);
      this.selectedFilter = name;
      this.provisionDisabled = false;
    });
    
    this.state={
      workspaceList :[],
      orgSorkspaceList : []
    };
    
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const store = this.envsStore;
    swallowError(store.load());
    store.startHeartbeat();

    const typesStore = this.envTypesStore;
    if (!isStoreReady(typesStore)) {
      swallowError(typesStore.load());
    }
    const wsList = this.getWSList();
    //this.setState({workspaceList:wsList});
    
  }
  
  getWSList(){
    
    runInAction(async () => {
      const store = this.envsStore;
     await store.doLoad();
      
      
    const selectedFilter = this.selectedFilter;
    let list = store.filtered(selectedFilter);
    list = list.sort((a,b)=> (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    const isEmpty = _.isEmpty(list);
    
    if(!isEmpty){
      const user = this.props.userStore.user;
      const prjStore =  this.props.projectsStore;
      var filteredWS = _.map(list, function(env) {
      const project =  prjStore.getProject(env.projectId);
      const isPrjOwner = _.includes(project.projectAdmins,user.uid);
      
      if(user.isAdmin)
      {
         return env;
      }else if(isPrjOwner)
       {
         return env;
        }else if(env.createdBy == user.uid){
         return env;
       }

      });
    }
    list = _.without(filteredWS, undefined);
    this.setState({workspaceList:list});
     this.setState({orgSorkspaceList:list});
   // return list;
    });
  }

  componentWillUnmount() {
    const store = this.envsStore;
    store.stopHeartbeat();
  }

  get isAppStreamEnabled() {
    return process.env.REACT_APP_IS_APP_STREAM_ENABLED === 'true';
  }

  get envTypesStore() {
    return this.props.envTypesStore;
  }

  get envsStore() {
    return this.props.scEnvironmentsStore;
  }
  
  getUserDisplayName() {
    return this.props.userDisplayName;
  }
  
  getUserStore() {
    const userStore = this.props.userStore;
    userStore.load();
    return userStore;
  }
  
/*  getUser() {
   // const userStore =this. getUserStore();
  //  return userStore.user;
  }*/

  getProjects() {
  //  const user = this.getUser();
    //const isAdmin = user?.isAdmin;
    //const uid =user?.uid;
    const store = this.getProjectsStore();
    
    let projectsData =  store.list;
    /* if(projectsData){
    
     projectsData= _.map(projectsData, (pjt) =>{
       
       const isPjtAdmin = _.find(pjt?.projectAdmins, (data)=>{
         console.log(data)
          if(uid == data){
            return true;
          }
        })
        const isPjtResearcher = _.find(pjt?.projectResearchers, (data)=>{
          console.log(data)
          if(uid == data){
           return true;
          }
        })
        const isPjtViewer = _.find(pjt?.projectViewers, (data)=>{
          console.log(data)
          if(uid == data){
            return true;
          }
        })
        
        if(isPjtAdmin || isPjtResearcher || isPjtViewer){
          return pjt;
        }
    })
     projectsData =_.without(projectsData, undefined);
    } */
    return projectsData;
  }

  getProjectsStore() {
    const store = this.props.projectsStore;
    store.load();
    return store;
  }

  handleCreateEnvironment = event => {
    event.preventDefault();
    event.stopPropagation();

    const goto = gotoFn(this);
    window.location = `/${workspacesRename.pluralLower}/create`;
    //goto(`/workspaces/create`);
  };

  handleSelectedFilter = name => {
    this.selectedFilter = name;
    const key = storageKeys.workspacesFilterName;
    storage.setItem(key, name);
  };

  render() {
    const store = this.envsStore;
    let content = null;
    const projects = this.getProjects();
    const appStreamProjectIds = _.map(
      _.filter(projects, proj => proj.isAppStreamConfigured),
      'id',
    );

    runInAction(() => {
      if (this.isAppStreamEnabled && _.isEmpty(appStreamProjectIds)) this.provisionDisabled = true;
    });

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0" />;
    } else if (isStoreLoading(store)) {
      content = <ProgressPlaceHolder segmentCount={3} />;
    } else if (isStoreEmpty(store)) {
      content = this.renderEmpty();
    } else if (isStoreNotEmpty(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }
    

  return (
      <Container className="mt3 animated fadeIn">
      <div className="ui basic segment p0">
        {this.renderTabs(content)}
        {this.provisionDisabled && this.renderMissingAppStreamConfig()}
      </div>
       
      </Container>
    );
  }
  
  getTabPanes(content,displayDataSet){
    
    const tabsArr =   [
      {
        menuItem: `${workspacesRename.pluralCap}`,
        render: () => <Tab.Pane attached={false}> {this.renderTitle()} {content}</Tab.Pane>,
      }
    ];
    
    if(displayDataSet){
      tabsArr.push(
        {
          menuItem: `${studiesRename.pluralCap}`,
          render: () => <Tab.Pane attached={false}><StudiesPage></StudiesPage></Tab.Pane>,
        });
    }
      return tabsArr;
  }
  
  handleViewDetail = (projectId) => {
    const goto = gotoFn(this);
    goto(`/${workspacesRename.pluralLower}/id/`+projectId);
  };
  
  renderTabs(content) {
    let isDisplayDataset = this.props?.displayDataSet;
    if(isDisplayDataset === undefined){
      isDisplayDataset=true;
    }
     return (
      <Tab menu={{ secondary: true, pointing: true }} panes={this.getTabPanes(content,isDisplayDataset)} />
    );
  }
  
  renderDetailTable(env) {
    //const studyCount = _.size(_.get(env, 'studyIds', []));
    
    const store = this.envTypesStore;
    const envType = store.getEnvType(env.envTypeId) || {};

    ///const config = this.getConfiguration(this.environment.envTypeConfigId);

    const renderRow = (key, value) => (
     
        <Table.Cell >{value}</Table.Cell>
        
      
    );

    return (
      <Table.Row>
        {renderRow(`${workspacesRename.singularCap} Name`, env.name)}
        {renderRow(`${projectsRename.singularCap}`, _.isEmpty(env.projectId) ? 'N/A' : env.projectId)}
        {renderRow('Owner', <By uid={env.createdBy} skipPrefix />)}
        {renderRow(`${workspacesRename.singularCap} Type`, envType.name)}
        <Table.Cell>
          <Label size="mini" color={env.state.color}>
            {env.state.spinner && <Icon name="spinner" loading />}
            {env.state.display}
          </Label>
        </Table.Cell>
        <Table.Cell>
          <Button floated="left" basic size="mini" className="mt1 mb1 ml2" onClick={(evt)=>{this.handleViewDetail(env.id)}}>
            View Detail
          </Button>
        </Table.Cell>
      </Table.Row>
         
    );
  }
  
  // renderProjectsTable(isEmpty,data){
  //   return (
  //     <div>
  //       <Table celled>
  //         <Table.Header>
  //           <Table.Row>
  //             <Table.HeaderCell>Workspace Name</Table.HeaderCell>
  //             <Table.HeaderCell>Project Name</Table.HeaderCell>
  //             <Table.HeaderCell>Created By</Table.HeaderCell>
  //             <Table.HeaderCell>Workspace Type</Table.HeaderCell>
  //             <Table.HeaderCell>State</Table.HeaderCell>
  //             <Table.HeaderCell>Details</Table.HeaderCell>
  //           </Table.Row>
  //         </Table.Header>
  //         <Table.Body>
          
          
  //           { _.map(data, item => (
  //             this.renderDetailTable(item)
  //           ))}
  //         </Table.Body>
  //       </Table>
  //     </div>
  //   );
  // }
  


  renderProjectsTable(isEmpty, data) {
    const pageSize = data.length;
    const pagination = data.length > pageSize;
    const projStore = this.getProjectsStore();
    const terminatedFilter = _.filter(
      data, function(workspace) {
        return workspace.status != "TERMINATED";
      });

    return (
      <div>
        <ReactTable
          data={terminatedFilter}
          defaultSorted={[{ id: 'id', desc: false }]}
          showPagination={pagination}
          defaultPageSize={pageSize}
          className="-striped -highlight"
          filterable
          defaultFilterMethod={(filter, row) => {
            const columnValue = String(row[filter.id]).toLowerCase();
            const filterValue = filter.value.toLowerCase();
            return columnValue.indexOf(filterValue) >= 0;
          }}
          sortable={[
              'id'
          ]}
          columns={[
            {
              Header: `${workspacesRename.singularCap} Name`,
              accessor: 'name'
            },
            {
              Header: `${projectsRename.singularCap} Name`,
              accessor: 'projectId',
              Cell: observer(cell => {
                const projCell = cell.original;
                const projId = projCell.projectId;
                const projObj = projStore.getProject(projId);
                const isSensitive = projObj.hasSensitiveData || false;
                return(<div>{projId} {isSensitive && <Popup trigger={<Icon name='exclamation triangle' color='red' />} content='This project uses sensitive, personally-identifiable data and is subject to additional data security requirements.' />}</div>);
              })
            },
            {
              Header: 'Created By',
              accessor: 'createdBy',
              Cell: observer(cell => {
                const createdCell = cell.original;
                const createdby = createdCell.createdBy;
                const userDisplayName = this.getUserDisplayName();
                const username = userDisplayName.getDisplayName({ uid: createdby });
                return (<div>{username}</div>);
              }),
              filterMethod: (filter, row) => {
                const createdBy = row.createdBy;
                const filterValue = filter.value.toLowerCase();
                const userDisplayName = this.getUserDisplayName();
                const displayName = userDisplayName.getDisplayName({ uid: createdBy }).toLowerCase();
                if(displayName.indexOf(filterValue) >= 0) {
                  return true;
                }
                else {
                  return false;
                }
              }
            },
            {
              Header: `${workspacesRename.singularCap} Type`,
              accessor: 'envTypeConfigId',
              //filterable: false,
              Cell: observer(cell => {
                const envCell = cell.original;
                const store = this.envTypesStore;
                const envType = store.getEnvType(envCell.envTypeId) || {};
                const name = envType.name;
                return (<div>{name}</div>);
              }),
              filterMethod: (filter, row) => {
                const orig = row._original;
                const store = this.envTypesStore;
                const envType = store.getEnvType(orig.envTypeId) || {};
                let workspaceTypeName = '';
                if(envType.name) {
                  workspaceTypeName = envType.name.toLowerCase();
                }
                const filterValue = filter.value.toLowerCase();
                if(workspaceTypeName.indexOf(filterValue) >= 0) {
                  return true;
                }
                else {
                  return false;
                }
              },
            },
            {
              Header: 'State',
              accessor: 'viewState',
              //filterable: false,
              Cell: observer(cell => {
                const statusCell = cell.original;
                const display = statusCell.state.display;
                const color = statusCell.state.color;
                const spinner = statusCell.state.spinner;
                return (<div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <Label size="mini" color={color}>
                    {spinner && <Icon name="spinner" loading />}
                    {display}
                  </Label></div>);
              }),
              filterMethod: (filter, row) => {
                if (filter.value === "Show All") {
                  return true;
                }
                else {
                  //console.log(filter);
                  //console.log(row);
                  //console.log(row._original.status);
                  //const columnValue = String(row[filter.id]).toLowerCase();
                  const columnValue = row._original.status.toLowerCase();
                  const filterValue = filter.value.toLowerCase();
                  return columnValue === filterValue;
                }
              },
              Filter: ({ filter, onChange }) =>
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}>
                  <option value="Show All">Show All</option>
                  <option value="Completed">Available</option>
                  <option value="Pending">Pending</option>
                  <option value="Stopped">Stopped</option>
                  <option value="Failed">Failed</option>
                  {/*<option value="Terminated">Terminated</option>*/}
                </select>
            },
            {
              Header: `Created Date`,
              accessor: 'createdAt',
              Cell: observer(cell => {
                const projCell = cell.original;
                const createdAt = projCell.createdAt;
                let date = new Date(createdAt);
                const month =  ("0" + (date.getMonth() + 1)).slice(-2);
                return `${date.getFullYear()}-${month}-${date.getDate()}`;
              }),
              filterMethod: (filter, row) => {
                /* get raw values */
                const createdAt = row.createdAt;
                const filterValue = filter.value;
                
                /* build a date string */
                let date = new Date(createdAt);
                const month =  ("0" + (date.getMonth() + 1)).slice(-2);
                const createdAtString = `${date.getFullYear()}-${month}-${date.getDate()}`;
                
                /* compare date string against filter value */
                if (createdAtString.indexOf(filterValue) >= 0) {
                  return true;
                }
                else {
                  return false;
                }
              }
            },
            {
              Header: 'Details',
              accessor: 'viewDetail',
              Cell: observer(cell => {
                const originalRow = cell.original;
                return (
                  <div style={{ textAlign: 'center' }}>
                    <Button basic size="mini" className="mt1 mb1 ml2" onClick={(evt) => { this.handleViewDetail(originalRow.id) }}>
                      View Detail
                    </Button>
                  </div>
                );
              }),
              filterable: false
            }
          ]}
        />
      </div>
    );
  }

  renderMissingAppStreamConfig() {
    return (
      <>
        <Segment placeholder className="mt2">
          <Header icon className="color-grey">
            <Icon name="lock" />
            Missing association with AppStream projects
            <Header.Subheader>
              Since your projects are not associated to an AppStream-configured account, creating a new {workspacesRename.singularLower} is
              disabled. Please contact your administrator.
            </Header.Subheader>
          </Header>
        </Segment>
      </>
    );
  }

  crawl = (proj, allValues) => {
      if (!allValues) allValues = [];
      //const keysObj = ['name'];
      for (var key in proj) {
        
        if (key == 'envTypeConfigId'){
          const envTypeId = proj[key];
          const store = this.envTypesStore;
          if(proj.envTypeId){
            const envType = store.getEnvType(proj.envTypeId);
            if(envType){
              const name = envType.name;
              allValues.push(name + " ");
            }
          }
        }else if (key == 'createdBy'){
          const createdBy = proj[key];//cell.original;
          const createdby = proj.createdBy;
          const userDisplayName = this.getUserDisplayName();
          const username = userDisplayName.getDisplayName({ uid: createdby });
          allValues.push(username + " ");
        }else if( key == 'name' || key == 'projectId'){
          allValues.push(proj[key] + " ");
        }
        // if(keysObj.includes(key)) {
        //   if(proj[key].length > 0) {
        //     for (let i = 0; i < proj[key].length; i++) {
        //       allValues.push(proj[key][i]);
        //     }
        //   }
        // }
          //if (typeof proj[key] === "object") this.crawl(proj[key], allValues);
          //else allValues.push(proj[key] + " ");
      }
      return allValues;
    }
   
   handleSearch =(event)=>{
    const searchTerm = event.target.value;
    const projectList = this.state.orgSorkspaceList;
    
    runInAction(async () => {
    //await this.getWSList();//this.getProjects();
    //var dataArray = Object.keys(projectList).map(val => projectList[val]);
    
    const filteredProjects = projectList.filter((project, index) => {
        const userDisplayName = this.getUserDisplayName();
        const allValues = this.crawl(project);
        //console.log(allValues);
        const finalValues = allValues.map(obj => {
      	  if (obj.startsWith("u-")) {
      		  const username = userDisplayName.getDisplayName({ uid: obj.trim() });
      		  //console.log(username);
      		  return username + " ";
      	  }
      	  return obj;
      	});
        if (finalValues.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
          return true;
        return false;
      });
      //console.log(filteredProjects);
      
      
     // this.setState({workspaceList:filteredProjects});
      
      this.setState({
        workspaceList: []
      },
      () => {
        this.setState({
            workspaceList: filteredProjects
          });
      }
    );
    
    });
  }

  
  renderMain() {
    const store = this.envsStore;
    const selectedFilter = this.selectedFilter;
    // let list = store.filtered(selectedFilter);
    // list = list.sort((a,b)=> (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    // const isEmpty = _.isEmpty(list);
    
    // if(!isEmpty){
      // list.filter((env)=>{
      //   const user = this.props.userStore.user;
      //   const prjStore =  this.props.projectsStore;
      //   const project =  prjStore.getProject(env.projectId);
      //   console.log(project);
        
      //   return project &&  project.createdBy == user.uid ? true:false;
      // })
      
      // const user = this.props.userStore.user;
      // const prjStore =  this.props.projectsStore;
    //   var filteredWS = _.map(list, function(env) {
    //     const project =  prjStore.getProject(env.projectId);
    //     // console.log("Project", project);
    //     // //if (project.createdBy == user.uid ) return env;
        
    //     // console.log( "CHeckIncludes", _.includes(project.projectAdmins,user.uid));
    //     // console.log("ProjectAdminsList", project.projectAdmins);
    //     // console.log("projArray", project.projectAdmins.target);
    //     // console.log("UserID", user.uid);
        
    //   const isPrjOwner = _.includes(project.projectAdmins,user.uid);
        
       
    //   if(user.isAdmin)
    //   {
    //     return env;
    //   }else if(isPrjOwner)
    //   {
    //     return env;
    //     }else if(env.createdBy == user.uid){
    //     return env;
    //   }

    //   });
    // }

      //console.log("** Filtered WS *****",filteredWS);
      // Remove undefines from the array
      // list = _.without(filteredWS, undefined);
    
    // console.log("** List of WSs *****",list);
    
    const list = this.state.workspaceList;
    const isEmpty = _.isEmpty(list);

    return (
      <div data-testid="workspaces" className="home">
        {/*
        <ScEnvironmentsFilterButtons
          selectedFilter={selectedFilter}
          onSelectedFilter={this.handleSelectedFilter}
          className="filters"
        />
        */}
        {!isEmpty && this.renderProjectsTable(isEmpty,list)
         /* _.map(list, item => (
            <Segment className="p3 mb4" clearing key={item.id}>
              <ScEnvironmentCard scEnvironment={item} />
            </Segment>
          ))*/
          
          
        }
        {isEmpty && (
          <Segment placeholder>
            <Header icon className="color-grey">
              <Icon name="server" />
              No research {workspacesRename.pluralLower} matching the selected filter.
              <Header.Subheader>Select &apos;All&apos; to view all the {workspacesRename.pluralLower}</Header.Subheader>
            </Header>
          </Segment>
        )}
      </div>
    );
  }

  renderEmpty() {
    return (
      <Segment data-testid="workspaces" placeholder>
        <Header icon className="color-grey">
          <Icon name="server" />
          No research {workspacesRename.pluralLower}
          <Header.Subheader>To create a research {workspacesRename.singularLower}, click Create Research {workspacesRename.singularCap}.</Header.Subheader>
        </Header>
      </Segment>
    );
  }

  renderTitle() {
    //const ws = Environments["prop1"];
    const ph = `Search ${workspacesRename.pluralCap}...`;
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="server" className="align-top" />
          <Header.Content className="left-align">Research {workspacesRename.pluralCap}</Header.Content>
        </Header>
        <div className="wsDiv">
        <Input icon='search' className="wsElements" placeholder={ph} onChange={this.handleSearch.bind(this)}/>
          {/*<Button
            data-testid="create-workspace"
            color="blue"
            size="medium"
            disabled={this.provisionDisabled}
            basic
            onClick={this.handleCreateEnvironment}
          >
            Create Research {Environments["prop2"]}
          </Button>*/}
        </div>
      </div>
    );
  }

  renderTotal() {
    const store = this.envsStore;
    if (isStoreError(store) || isStoreLoading(store)) return null;

    return <Label circular>{store.total}</Label>;
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(ScEnvironmentsList, {
  selectedFilter: observable,
  provisionDisabled: observable,
  envsStore: computed,
  envTypesStore: computed,
  handleCreateEnvironment: action,
  handleSelectedFilter: action,
});

export default inject(
  'scEnvironmentsStore',
  'projectsStore',
  'envTypesStore',
  'usersStore',
  'userStore',
  'userDisplayName',
)(withRouter(observer(ScEnvironmentsList)));

