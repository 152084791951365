import React from 'react';
import { Pie, Line, Bar } from 'react-chartjs-2';

const ChartLayout = () => {
  // Sample data for the charts
  const pieChartData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: 'Sample Dataset',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: ['red', 'blue', 'yellow', 'green', 'purple', 'orange'],
      },
    ],
  };

  const lineChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Sample Dataset',
        data: [65, 59, 80, 81, 56, 55],
        fill: false,
        borderColor: 'rgba(255, 99, 132, 0.6)',
      },
    ],
  };

  const barChartData = {
    labels: ['A', 'B', 'C', 'D', 'E'],
    datasets: [
      {
        label: 'Sample Dataset',
        data: [12, 19, 3, 5, 2],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
    ],
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '20px' }}>
      <div>
        <h2>Pie Chart</h2>
        <Pie data={pieChartData} />
      </div>
      <div>
        <h2>Line Chart</h2>
        <Line data={lineChartData} />
      </div>
      <div>
        <h2>Bar Chart</h2>
        <Bar data={barChartData} />
      </div>
      {/* Add more charts in a similar way */}
    </div>
  );
};

export default ChartLayout;
