/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import { createForm } from '../../helpers/form';
import { workspacesRename, projectsRename, indexesRename } from '../../helpers/settings';

const addProjectFormFields = {
  id: {
    label: `${projectsRename.singularCap} Name`,
    placeholder: `Type name for this ${projectsRename.singularLower}`,
    rules: 'required|string|between:1,300',
  },
  indexId: {
    label: `${indexesRename.singularCap} ID`,
    rules: 'required|string',
  },
  description: {
    label: 'Description',
    placeholder: `Type description for this ${projectsRename.singularLower}`,
    rules: 'string|between:1,3000',
  },
  projectAdmins: {
    label: `${projectsRename.singularCap} Admins`,
    placeholder: `Users who automatically have full control of ${workspacesRename.pluralLower} associated with this ${projectsRename.singularLower}`,
    explain: ' ',
    rules: 'array',
  },
};

function getAddProjectFormFields() {
  return addProjectFormFields;
}

function getAddProjectForm() {
  return createForm(addProjectFormFields);
}

export { getAddProjectFormFields, getAddProjectForm };
